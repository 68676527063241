import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/models/group';
import { UtilService } from 'src/app/services/util.service';
import { CreationService } from '../creation.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css', '../creation.component.scss','./group.component.scss']
})
export class GroupComponent implements OnInit {


  constructor( private utilService: UtilService,
    public creationService: CreationService,
    public configService: ConfigService) {}

  ngOnInit(): void {}

  onSelect(group: Group): void {
    this.creationService.step = 2;
    this.creationService.stepMax = 2;
    this.creationService.selectedGroup = group;
    this.creationService.selectedType = null;
    this.creationService.uploadFiles = new Array();
    this.creationService.requestMessage = '';
    this.creationService.creationLabel = '';
  }

  groupImage(groupId: number) {
    return this.utilService.getGroupImageUrl(groupId);
  }

}
