
  <div class = "container-fluid container-type mx-auto" [class.groupSelected]="creationService.selectedGroup" [style.margin-top.px]="setMarginTop()">
    <div class="row-msx row-type justify-content-center" [class.groupTypeActive]="creationService.selectedType">
      <div *ngFor="let type of types" class="col-12 col-sm-9 col-md-5 col-lg-3 col-xl-2 col-xxl-2 vignette-center" (click)="onSelect(type)">
        <button class="buttonGroupType type-label" [class.buttonGroupTypeActive]="type == creationService.selectedType" [ngClass]="{'buttonGroupTypeUnactive buttonGroupTypeMobile': creationService.selectedType!=null && type != creationService.selectedType}">

          <div *ngIf="(creationService.selectedType == null) && (typeImage(type.id) !== '')" class="vignette-img">
            <img src="{{typeImage(type.id)}}"/>
          </div>
          <div id="name">{{configService.lang === 'fr-FR' ? type.nameFR : type.nameEN}}</div>
        </button>
      </div>
    </div>
    <div [class.typeSelected]="creationService.selectedType">
      <div class="row-msx row-label align-content-center justify-content-center ">
        <div class="col">
          <div class="row-msx">
            <div class="col">
              <div class="label-title">{{ 'type.labelText' | translate }}  <span class="label-optional">({{ 'type.optional' | translate }})</span></div>
              <div class="label-title2">{{ 'type.labelText2' | translate }}</div>
            </div>
          </div>
          <div class="row-msx">
            <div class="col-md-6">
              <input type="text"
                     (keyup)="onKeyUp($event)"
                     [(ngModel)]="creationService.creationLabel"
                     name="creationLabel"
                     class="form-control"
                     placeholder="{{group.id == 16 ? ('type.exampleLabelEAC' | translate) : ('type.exampleLabel' | translate)}}"
                     aria-label="Username"
                     aria-describedby="basic-addon1"
                     maxlength="30">
              <div class="char-counter">{{creationLabelCounter}} / 30</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-msx justify-content-center">
        <button type="button" [disabled]="checkButton()" (click)="onSubmit()" class="btnCustom">{{ 'type.validate' | translate }}</button>
      </div>
    </div>
    <div *ngIf="configService.isMobile === true" class="padding-mobile"></div>
  </div>

