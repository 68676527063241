import {ConfigService} from './../../services/config.service';
import {CreationService} from './creation.service';
import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import {GroupService} from 'src/app/services/group.service';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-creation',
    templateUrl: './creation.component.html',
    styleUrls: ['../../../styles/bootstrap.min.css', './creation.component.scss']
})
export class CreationComponent implements OnInit {
    siteUrl = environment.siteUrl;

    constructor(private router: Router, private configService: ConfigService, public creationService: CreationService, public groupService: GroupService, private route: ActivatedRoute) {
    }

    async ngOnInit(): Promise<void> {
        if (this.configService.extranet === 'EAC') {
            this.configService.collectiviteEAC = this.collectiviteName();
        }
        this.newLandingPageCheck();
    }

    cancel(): void {
        this.creationService.clean();
        if (this.configService.extranet === 'EXM') {
            this.router.navigate([environment.siteUrlEXM]);
        } else if (this.configService.extranet === 'EXM-embark') {
            this.router.navigate([environment.siteUrlEmbarkEXM]);
        } else if (this.configService.extranet === 'EIM') {
            this.router.navigate([environment.siteUrlEIM]);
        } else if (this.configService.extranet === 'EIM-embark') {
            this.router.navigate([environment.siteUrlEmbarkEIM]);
        } else {
            this.router.navigate(['/']);
        }

    }

    changeStep(step: string): void {
        if (step === 'next' && (this.creationService.stepMax > this.creationService.step)) {
            ++this.creationService.step;
        } else if (step === 'before' && (1 < this.creationService.step && this.creationService.step < 4)) {
            --this.creationService.step;
        } else if (this.creationService.stepMax >= Number(step)) {
            this.creationService.step = Number(step);
        }
    }

    collectiviteName(): string {
        const nameElement = document.getElementsByClassName('tq-hidden-collectivite-name-msx')[0];
        const inputElement = nameElement.getElementsByTagName('input')[0];
        return inputElement.value;
    }

    newLandingPageCheck(): void {
        if (this.configService.customLandingPage && this.configService.customLandingPage === 'creation') {
            this.groupService.getGroups().pipe(
                tap(g => this.creationService.groups = g.groups.filter(gr => gr.creation))
            ).subscribe(() => {
                // case when we are landing on the creation page with a group and type preselected
                // for now this functionality is not used
                // @TODO It's better to use attributes for group and type selection
                // (like for navigation)
                const groupIdEl = document.getElementById('msx-cr-groupId') as HTMLInputElement;
                const typeIdEl = document.getElementById('msx-cr-typeId') as HTMLInputElement;
                const groupId = groupIdEl ? groupIdEl.value : null;
                const typeId = typeIdEl ? typeIdEl.value : null;
                if (groupId) {
                    this.creationService.selectedGroup = this.creationService.groups.find(g => g.id.toString() === groupId);
                    if (this.creationService.selectedGroup) {
                        this.creationService.step = 2;
                        this.creationService.stepMax = 2;
                        if (typeId) {
                            this.creationService.selectedType = this.creationService.selectedGroup.types.find(
                                type => type.id.toString() === typeId);
                            if (this.creationService.selectedType) {
                                this.creationService.step = 3;
                                this.creationService.stepMax = 3;
                                console.log('Complex request - Type found', this.creationService.selectedType);
                            }
                        }
                    }
                }
            });
        }
    }
}
