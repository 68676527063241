import { CreationService } from 'src/app/pages/creation/creation.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service';
import { ReplyService } from 'src/app/services/reply.service';

@Component({
  selector: 'app-reply-creation',
  templateUrl: './reply-creation.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./reply-creation.component.scss']
})
export class ReplyCreationComponent implements OnInit {

  siteUrl = environment.siteUrl;
  sharedId : number;
  isReplyPage: boolean = true;

  constructor(private configService: ConfigService, public creationService: CreationService, private replyService: ReplyService) { 

    document.body.style.margin = "0";
    this.sharedId = this.replyService.currentId ? this.replyService.currentId : null;

  }
  
  ngOnInit(): void {
    
    this.creationService.selectedGroup = this.replyService.group;
    this.creationService.selectedType = this.replyService.type;

  }

  cancel(){
    this.creationService.clean();
    this.replyService.currentId = null;
    this.replyService.group = null;
    this.replyService.type = null;
  }

}
