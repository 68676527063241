import { CreationService } from 'src/app/pages/creation/creation.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service';
import { ReplyService } from 'src/app/services/reply.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advisor-creation',
  templateUrl: './advisor-creation.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./advisor-creation.component.scss']
})
export class AdvisorCreationComponent implements OnInit {

  siteUrl = environment.siteUrl;
  sharedId : number;
  advisorPage: boolean = true;

  constructor(private router: Router,
              private configService: ConfigService,
              public creationService: CreationService,
              private replyService: ReplyService) {

    document.body.style.margin = "0";
    this.sharedId = this.replyService.currentId ? this.replyService.currentId : null;

  }
  
  ngOnInit(): void {

  }

  cancel(){
    this.creationService.clean();
    this.router.navigate(['/advisor'], { queryParams: {id: this.sharedId}});
  }

}
