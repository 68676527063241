import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Groups} from '../models/groups';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class GroupService {
    private groups: Groups;
    private url = environment.bffUrl + '/v1/groups';

    constructor(private http: HttpClient) {
    }

    /**
     * Ensure only one call to the API and then use the cached data
     */
    getGroups(): Observable<Groups> {
        if (this.groups) {
            return of(this.groups);
        } else {
            return this.http.get<Groups>(this.url).pipe(
                tap(groups => this.groups = groups)
            );
        }
    }
}
