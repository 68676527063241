import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from 'src/environments/environment';


export const authCodeFlowConfigEmbark: AuthConfig = {
    issuer: environment.ssoEmbarkUrlIssuer,

    // loginUrl: environment.ssoEmbarkUrl + '/authorize',
    // tokenEndpoint: environment.ssoEmbarkUrl + '/access-token',

    // URL of the SPA to redirect the user to after login
    redirectUri: environment.ssoEmbarkRedirectUrl, // !!! will be overridden in AppComponent

    clientId: environment.ssoEmbarkClientId,
    // clientId: '4889f056-35c8-4ef2-ac9a-22903b7215fe', // UAT
    // clientId: '22eb051a-cc6d-488e-8552-33faf33ea7b7', // DEV
    // postLogoutRedirectUri: environment.ssoEmbarkLogoutRedirectUrl,
    responseType: 'code',
    scope: 'openid',    
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 5000, // For faster testing
    skipIssuerCheck: true,
    customQueryParams: {
        'clientCountryId': environment.ssoEmbarkClientCountryId
        // 'clientCountryId': '3' // UAT
        // 'clientCountryId': '5830' // DEV
        // 'state': 'Oidc',
        // 'nonce': 862918,
        // 'notice': '9/22/2021'
},
    showDebugInformation: true,
    clearHashAfterLogin: false,
    // turn off validation that discovery document endpoints start with the issuer url defined above
    strictDiscoveryDocumentValidation: false
  };

