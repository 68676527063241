import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { LocalizationService } from 'src/app/internationalization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private localizationService: LocalizationService) { }

  format(date: Date, lang: string) {
    if (lang === 'fr-FR')
        return moment(date).format('DD/MM/YYYY');
    else
        return moment(date).format('MM/DD/YYYY');
  }
  formatDateTime(date: Date, lang: string) {
    if (lang === 'fr-FR')
        return moment(date).format('DD/MM/YYYY HH:mm');
    else
        return moment(date).format('MM/DD/YYYY HH:mm');
  }

  getStatusLabel(status: string) {
      if (status === 'SENT')
        return this.localizationService.translate('util.statusSent');
      else if (status === 'PENDING')
        return this.localizationService.translate('util.statusPending');
      else if (status === 'RECEIVED')
        return this.localizationService.translate('util.statusRecieved');
      else if (status === 'CLOSED')
        return this.localizationService.translate('util.statusClosed');
      else if (status === 'WARNING')
        return this.localizationService.translate('util.statusWarning');
      else if (status === 'DELETED')
        return this.localizationService.translate('util.statusDeleted');
      else
        return this.localizationService.translate('util.statusUnknown');
  }

  getClientOrInternalLabel(CoI: string) {
      if (CoI === 'C')
        return this.localizationService.translate('util.CoIEmitted');
      else if (CoI === 'I')
        return this.localizationService.translate('util.CoIReceived');
      else
        return this.localizationService.translate('util.statusUnknown');
  }

  getGroupImageUrl(groupId: number) {
    if (groupId === 1) {
      return environment.siteUrl + '/assets/groups/Famille_Demande_Remboursement.svg';
    }
    else if (groupId === 2) {
      return environment.siteUrl + '/assets/groups/Famille_Adhesion.svg';
    }
    else if (groupId === 3) {
      return environment.siteUrl + '/assets/groups/Famille_Envoi_justificatifs.svg';
    }
    else if (groupId === 4) {
      return environment.siteUrl + '/assets/groups/Famille_Demande_Informations.svg';
    }
    else if (groupId === 5) {
      return environment.siteUrl + '/assets/groups/Famille_Reclamation.svg';
    }
    else if (groupId === 6) {
      return environment.siteUrl + '/assets/groups/Famille_Devis.svg';
    }
    else if (groupId === 7) {
      return environment.siteUrl + '/assets/groups/Famille_PEC_Hospi.svg';
    }
    else if (groupId === 8) {
      return environment.siteUrl + '/assets/groups/Famille_Affiliation.svg';
    }
    else if (groupId === 9) {
      return environment.siteUrl + '/assets/groups/Famille_Radiation.svg';
    }
    else if (groupId === 10) {
      return environment.siteUrl + '/assets/groups/Famille_Dossiers_Prev.svg';
    }
    else if (groupId === 11) {
      return environment.siteUrl + '/assets/groups/Famille_Mutation.svg';
    }
    else if (groupId === 12) {
      return environment.siteUrl + '/assets/groups/Famille_Cotisations.svg';
    }
    else if (groupId === 13) {
      return environment.siteUrl + '/assets/groups/Famille_Autre_demande.svg';
    }
    else if (groupId === 14) {
      return environment.siteUrl + '/assets/groups/Famille_Sinistres.svg';
    }
    else if (groupId === 15) {
      return environment.siteUrl + '/assets/groups/Famille_Medical.svg';
    }
    else if (groupId === 16) {
      return environment.siteUrl + '/assets/groups/Famille_Interfaces.svg';
    }
    else if (groupId === 17) {
      return environment.siteUrl + '/assets/groups/Famille_Remboursements_CFE_&_1erEuro.svg';
    }
    else if (groupId === 18) {
      return environment.siteUrl + '/assets/groups/Famille_Demande_Remboursement.svg';
    }
    else if (groupId === 19) {
      return environment.siteUrl + '/assets/groups/Famille_Demande_Remboursement.svg';
    }
    else if (groupId === 20) {
      return environment.siteUrl + '/assets/groups/Famille_Demande_Remboursement.svg';
    }
    else if (groupId === 23) {
      return environment.siteUrl + '/assets/groups/Famille_Resiliation.svg';
    }
    else if (groupId === 66) {
      return environment.siteUrl + '/assets/types/picto_medecine_douce.svg';
    }
    else {
      return '';
    }
  }

  getTypeImageUrl(typeId: number) {
    if (typeId === 1)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Optique_Dentaire.svg';
    else if (typeId === 2)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Autre.svg';
    else if (typeId === 3)
      return environment.siteUrl + '/assets/types/Type_Changement_RIB.svg';
    else if (typeId === 4)
      return environment.siteUrl + '/assets/types/Type_Modification_Familiale.svg';
    else if (typeId === 5)
      return environment.siteUrl + '/assets/types/Type_Modification_Adresse.svg';
    else if (typeId === 6)
      return environment.siteUrl + '/assets/types/Type_Justificatif_Pole_Emploi.svg';
    else if (typeId === 7)
      return environment.siteUrl + '/assets/types/Type_Attestation_vitale.svg';
    else if (typeId === 8)
      return environment.siteUrl + '/assets/types/Type_Certificat_Scolarite.svg';
    else if (typeId === 9)
      return environment.siteUrl + '/assets/types/Type_Devis_Optique.svg';
    else if (typeId === 10)
      return environment.siteUrl + '/assets/types/Type_Devis_Dentaire.svg';
    else if (typeId === 11)
      return environment.siteUrl + '/assets/types/Type_Devis_Audioprotheses.svg';
    else if (typeId === 12)
      return environment.siteUrl + '/assets/types/Type_Devis_Autre.svg';
    else if (typeId === 13)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Optique_Dentaire.svg';
    else if (typeId === 14)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Hospi.svg';
    else if (typeId === 15)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Autre.svg';
    else if (typeId === 16)
      return environment.siteUrl + '/assets/types/Type_Modif_Coordonnees_Bancaires.svg';
    else if (typeId === 17)
      return environment.siteUrl + '/assets/types/Type_Modification_Pays_Expat.svg';
    else if (typeId === 18)
      return environment.siteUrl + '/assets/types/Type_Certificat_Affiliation.svg';
    else if (typeId === 19)
      return environment.siteUrl + '/assets/types/Type_Certificat_Radiation.svg';
    else if (typeId === 20)
      return environment.siteUrl + '/assets/types/Type_Declaration_Masse_Salariale.svg';
    else if (typeId === 21)
      return environment.siteUrl + '/assets/types/Type_Liste_Personnel.svg';
    else if (typeId === 22)
      return environment.siteUrl + '/assets/types/Type_Autres_Documents.svg';
    else if (typeId === 23)
      return environment.siteUrl + '/assets/types/Type_Demande_Information.svg';
    else if (typeId === 24)
      return environment.siteUrl + '/assets/types/Type_Declaration_Sinistre.svg';
    else if (typeId === 25)
      return environment.siteUrl + '/assets/types/Type_Justificatifs_Dossier_Sinistre.svg';
    else if (typeId === 26)
      return environment.siteUrl + '/assets/types/Type_Demande_Expertise_Contre_Visite.svg';
    else if (typeId === 27)
      return environment.siteUrl + '/assets/types/Type_Gestion_Sinistres.svg';
    else if (typeId === 28)
      return environment.siteUrl + '/assets/types/Type_Gestion_Cotisations.svg';
    else if (typeId === 29)
      return environment.siteUrl + '/assets/types/Type_Gestion_Contrat_Adhesions.svg';
    else if (typeId === 30)
      return environment.siteUrl + '/assets/types/Type_Autres.svg';
    else if (typeId === 31)
      return environment.siteUrl + '/assets/types/Type_Fichiers_Interfaces.svg';
    else if (typeId === 32)
      return environment.siteUrl + '/assets/types/Type_Autres.svg';
    else if (typeId === 33)
      return environment.siteUrl + '/assets/types/Type_Remboursement_Hospi.svg';
    else if (typeId === 66)
      return environment.siteUrl + '/assets/types/picto_medecine_douce.svg';
    return '';
    }

  computeChecksum(file: File): Promise<string> {

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onerror = function(): void {
        reject('SHA-256 computation failed - error reading the file');
      };

      fileReader.readAsText(file);

      fileReader.onload=async function(){
        const utf8 = new TextEncoder().encode(fileReader.result.toString());
        resolve(crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');
          return hashHex;
        }));
      };

    });

  }

  b64EncodeUnicode(str) {
    const uriEncoding = encodeURIComponent(str)
        .replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(
                parseInt(p1, 16)
            );
        }
    );
    return btoa(uriEncoding);
  }

  onDomChange(mutationRecord: MutationRecord): void {
    const addedNodes = mutationRecord.addedNodes;
    const parent = mutationRecord.target;
    let attributes: NamedNodeMap | null = null;
    if (addedNodes.length > 0
      && addedNodes.item(0)['attributes'] !== undefined
      && addedNodes.item(0)['attributes'] instanceof NamedNodeMap
      ) {
        const parentAttribute = mutationRecord.target['attributes'];
        let uniqueId = '';
        for (let i = 0; i < parentAttribute.length; i++) {
          if(parentAttribute[i].name.startsWith('_ngcontent-')) {
            uniqueId = parentAttribute[i].name;
            break;
          }
        }
        if (uniqueId !== '') {
          (addedNodes.item(0) as HTMLElement).setAttribute(uniqueId, "");
          if ((addedNodes.item(0) as HTMLElement).children.length) {
            this.onDomChangeChildren((addedNodes.item(0) as HTMLElement), uniqueId);
          }
        }
      }
  }

  onDomChangeChildren (el: HTMLElement, uniqueId: string) {
    let elLength = el.children.length;
    for (let i = 0; i < elLength; i++) {
      el.children[i].setAttribute(uniqueId, '');
      if ((el.children[i] as HTMLElement).children.length) {
        this.onDomChangeChildren((el.children[i] as HTMLElement), uniqueId);
      }
    }
  }

}
