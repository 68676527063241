import { FilterComponent } from './filters/filter.component';
import { HistoricalRequestComponent } from './historical-request.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MainLayoutModule } from 'src/app/core/main-layout.module';
import { InternationalizationModule } from 'src/app/internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient} from '@angular/common/http';
import { NgbModule, NgbNavModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { DomChangeDirectiveModule } from 'src/app/directives/dom-change-directive/dom-change-directive.module';

export function HttpLoaderFactory(http: HttpClient) {
  if(location.origin === environment.advisorUrl)
        return new TranslateHttpLoader(http, environment.advisorUrl + '/assets/locales/', '.json');
    else
        return new TranslateHttpLoader(http, environment.siteUrl + '/assets/locales/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MainLayoutModule,
    DomChangeDirectiveModule,
    NgbModule,
    NgbNavModule,
    NgbDropdownModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    HistoricalRequestComponent, 
    FilterComponent
  ],
  exports: [HistoricalRequestComponent, FilterComponent]
})
export class HistoricalRequestModule { }
