import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsCheckBoxAdvisorService {
  
  checkboxStatusList = [
    {code: 'OUI', selected: false}, 
    {code: 'NON', selected: false}
  ];
  
  constructor() { }

  selectStatus(status) {
      this.checkboxStatusList = this.checkboxStatusList.map(s => {
        if (s.code === status.code) {          
          if(!s.selected) {
            this.cleanFilters();
            s.selected = !s.selected
          } else {
            s.selected = !s.selected
          }
        }
        return s;
      })
  }

  hasAnyCheckbox(): boolean {
    if (this.checkboxStatusList.find(s => s.selected)) {
      return true;
    }
    return false;
  }

  cleanFilters() {
    this.checkboxStatusList = this.checkboxStatusList.map(s => {
      s.selected = false;
      return s;
    });
  }


}
