import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';

import { CreationModule } from '../creation/creation.module';
import { AdvisorCreationComponent } from './advisor-creation.component';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
 export function HttpLoaderFactory(http: HttpClient) {
  if(location.origin === environment.advisorUrl)
        return new TranslateHttpLoader(http, environment.advisorUrl + '/assets/locales/', '.json');
    else
        return new TranslateHttpLoader(http, environment.siteUrl + '/assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AdvisorCreationComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    CreationModule,
    AppRoutingModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [AdvisorCreationComponent]
})
export class AdvisorCreationModule { }
