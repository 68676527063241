import { Injectable } from '@angular/core';
import { Group } from '../models/group';
import { Type } from '../models/type';
import { Client } from '../models/client';

@Injectable({
  providedIn: 'root'
})
export class ReplyService {

  currentId : number;
  group: Group;
  type: Type;
  advisorPage : boolean;
  client : Client;

  constructor() { }

}
