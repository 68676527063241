import { FilterService } from './filter.service';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { Group } from 'src/app/models/group';
import { RequestService } from './../../../services/request.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css','./filter.component.scss']
})
export class FilterComponent implements OnInit {
  siteUrl = environment.siteUrl;
  statusList = [];
  groupList = [];
  clientOrInternalList = [];

  constructor(private utilService: UtilService, private configService: ConfigService, private filterService: FilterService,private requestService: RequestService) { }

  ngOnInit() {
    this.statusList = this.filterService.statusList;
    this.clientOrInternalList = this.filterService.clientOrInternalList;
    this.filterService.groupList$.subscribe(groupList => this.groupList = groupList);
    this.filterService.initGroupList();
  }

  selectStatus(status) {
    this.filterService.selectStatus(status);
    this.applyFilters();
  }

  selectGroup(group) {
    this.filterService.selectGroup(group);
    this.applyFilters();
  }

  selectClientOrInternal(CoI) {
    this.filterService.selectClientOrInternal(CoI);
    this.applyFilters();
  }

  statusLabel(status: string) {
    return this.utilService.getStatusLabel(status);
  }
  groupName(group: Group) {
    return this.configService.lang === 'fr-FR' ? group.nameFR : group.nameEN;
  }
  clientOrInternalLabel(CoI: string) {
    return this.utilService.getClientOrInternalLabel(CoI);
  }

  checkedIcon(selected: boolean) {
    return this.siteUrl + (selected ? '/assets/filter/check-button-selected.svg' : '/assets/filter/check-button-unselected.svg');
  }
  applyFilters()  {
    this.filterService.applyFilters(this.requestService.requests);
    this.filterService.applyFiltersPfn(this.requestService.requestsPFN);
  }
}
