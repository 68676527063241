import { AttachmentRule } from './../models/attachmentRule';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  extranet: string;
  profileName: string;
  lang: string;
  domain: string;
  attachmentRules: AttachmentRule[] = [];
  bffToken: string;
  idToken: string;
  advisorIdToken: string;
  ssoRedirectUrl: string;
  collectiviteEAC: string = '';
  ssoRedirectFlag: boolean = false;
  isEmbark: boolean;
  isMobile: boolean;
  customLandingPage: string;

  url = environment.bffUrl + '/v1/configurations';

  constructor(private http: HttpClient) { }

  getConfig(): Observable<HttpResponse<Config>> { 
    return this.http.get<Config>(this.url, { headers: { "msx-extranet": this.extranet}, observe: 'response'});
  }

  clean(): void {
    this.extranet = '';
    this.profileName = '';
    this.lang = '';
    this.domain = '';
    this.attachmentRules = new Array();
    this.bffToken = '';
    this.idToken = '';
    this.advisorIdToken = '';
    this.ssoRedirectUrl = '';
    this.collectiviteEAC = '';
    this.ssoRedirectFlag = false;
    this.isEmbark = false;
    this.isMobile = false;
    this.customLandingPage = '';
  }

  /*
  getAttachmentRule(groupId: number, typeId?: number): AttachmentRule {

    let result: AttachmentRule;

    if (this.attachmentRules.length === 0) 
      result = null;
    else {
      result = this.attachmentRules.find(rule => {
        if((rule.groupId === groupId) && (rule.typeId === typeId))
          return true;
      });
      
      return result;
    }
    
  }*/
  /*
  getAttachmentRuleByTypeId(typeId: number): AttachmentRule {
    if (this.attachmentRules.length === 0) 
      return null
    else {
      return this.attachmentRules.find(rule => {
        if(rule.typeId == typeId)
          return true;
      });
    }
  }
  */
}
