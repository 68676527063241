import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthGuardAzureService implements CanActivate {

  constructor(public auth: OAuthService, public router: Router) { }

  canActivate(): boolean {
    let url = location.pathname + location.search;
    if (!environment.azureAdEnabled) {
      return true;
    }
    if (!location.origin.includes(environment.advisorUrl) )
      return false;
    if (!this.auth.hasValidAccessToken()) {
        this.auth.tryLogin().then(()=> {
            console.log('initCodeFlow');
            this.auth.initCodeFlow(url);
              }).then(() => {
                console.log('access token', this.auth.getAccessToken());
                console.log('hasValidAccessToken', this.auth.hasValidAccessToken());
              })
      return false;
    }
    let claims = this.auth.getIdentityClaims();
    console.log('token info: ',claims);

    if (Object(claims)["roles"].includes(environment.roleAzureAd)){
        return true
    } else {
        console.log('Bad azureAD role');
    }

    return false;
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }
}