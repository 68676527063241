<div class="row-msx container-filters">

  <h3 class="h3-msx">{{ 'filter.clientOrInternalTitle' | translate }}</h3>

  <ul class="list-filter">
    <li class="row-msx filter" *ngFor="let CoI of clientOrInternalList" (click)="selectClientOrInternal(CoI)">
      <div class="col-10 d-flex">
        <div class="d-flex align-items-center">{{ clientOrInternalLabel(CoI.code) }}</div>
      </div>
      <div class="col-2">
        <img src="{{ checkedIcon(CoI.selected) }}"/>
      </div>
    </li>
  </ul>

  <h3 class="h3-msx">{{ 'filter.statusTitle' | translate }}</h3>
  
  <ul class="list-filter">
    <li class="row-msx filter" *ngFor="let status of statusList" (click)="selectStatus(status)">
      <div class="col-10 d-flex">
        <div class="d-flex align-items-center">{{ statusLabel(status.code) }}</div>
      </div>
      <div class="col-2">
        <img src="{{ checkedIcon(status.selected) }}"/>
      </div>
    </li>    
  </ul>
  
  <h3 class="h3-msx">{{ 'filter.groupTitle' | translate }}</h3>
  
  <ul class="list-filter">
    <li class="row-msx filter" *ngFor="let group of groupList" (click)="selectGroup(group)">
      <div class="col-10 d-flex">
        <div class="d-flex align-items-center">{{ groupName(group) }}</div>
      </div>
      <div class="col-2">
        <img src="{{ checkedIcon(group.selected) }}"/>
      </div>
    </li>
  </ul>

  
</div>