import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { DirectivesDirective } from './directives/directives.directive';
import { BreakpointService } from './services/breakpoint.service';


@NgModule({
  declarations: [
   MainLayoutComponent,
   DirectivesDirective
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    RouterModule.forRoot([])
  ],
  exports: [
    MainLayoutComponent,
   DirectivesDirective
  ],
  providers: [
    BreakpointService
  ]
})
export class MainLayoutModule { }
