  <div class="mx-auto" >
      <h2 *ngIf="!creationService.selectedGroup" class="h2-msx group-title justify-content-center">{{ 'group.title' | translate }}</h2>
      <h2 *ngIf="creationService.selectedGroup" class="h2-msx group-title justify-content-center">{{ 'type.title' | translate }}</h2>
  </div>

  <div class = "container-fluid container-type padding-group" [class.groupTypeActive]="creationService.selectedGroup && creationService.step == 2" [class.wrap]="creationService.selectedGroup && creationService.step == 2">
    <div class="row-msx row-type justify-content-center">
      <div *ngFor="let group of creationService.groups" class="col-12 col-sm-9 col-md-5 col-lg-3 col-xl-2 col-xxl-2 vignette-center" (click)="onSelect(group)" >
        <button class="buttonGroupType group-name" [class.buttonGroupTypeActive]="group === creationService.selectedGroup" [class.buttonGroupTypeUnactive]="creationService.selectedGroup!=null && group !== creationService.selectedGroup" [class.buttonGroupTypeMobile]="creationService.selectedGroup!=null && group !== creationService.selectedGroup && creationService.step == 2">
          <div *ngIf="(creationService.step == 1) && (groupImage(group.id) !== '')" class="vignette-img">
            <img src="{{groupImage(group.id)}}"/>
          </div>        
          <div id=name >{{configService.lang === 'fr-FR' ? group.nameFR : group.nameEN}}</div>
        </button>
      </div>
    </div>
  </div>
  <app-type *ngIf="creationService.step == 2" [group]="creationService.selectedGroup"></app-type>

