export const environment = {
  name: 'int',
  production: false,
  bffUrl: 'https://int.msx.grassavoye.com/bff/api',
  advisorUrlAPI: 'https://intadmin.msx.grassavoye.com/advisor/api',
  siteUrl: 'https://int.msx.grassavoye.com',
  siteUrlEXM: 'assets/extranet/int/exm.html',
  siteUrlEmbarkEXM: 'assets/extranet/int/exm-embark.html',
  siteUrlEIM: 'assets/extranet/int/eim.html',
  siteUrlEmbarkEIM: 'assets/extranet/int/eim-embark.html',
  azureAdEnabled: true,
  advisorUrl: 'https://intadmin.msx.grassavoye.com',
  azureAdTenantId:'76e3921f-489b-4b7e-9547-9ea297add9b5',
  redirectUriAdvisor:'https://intadmin.msx.grassavoye.com',
  azureClientId: 'c83c9802-a5ee-4cdf-8705-e1ca14c150bf',
  scopeAzure: 'email openid profile User.Read',
  roleAzureAd: 'FR_MSX_Auth_SSO_AzureAD_RCT',
  ssoEnabled: true,
  ssoUrlIssuer: 'https://sso-rct.grassavoye.com/openam/oauth2/realms/PTLQAL',
  ssoUrl: 'https://sso-rct.grassavoye.com/openam/oauth2/realms/PTLQAL',
  ssoRedirectUrl: 'https://int.msx.grassavoye.com',
  ssoRedirectUrlEXA: 'https://extranet-exa-int.witiwi.fr/fr/mailbox',
  ssoRedirectUrlERH: 'https://extranet-rh-int.witiwi.fr/fr/ma-messagerie',
  ssoRedirectUrlERHPQL: '',
  ssoRedirectUrlEAC: 'https://int.extranet-adp.grassavoye.com/fr/mailbox',
  ssoRedirectUrlEXI: 'https://extranet-exa-int.witiwi.fr/fr/mailbox',
  ssoRedirectUrlEXM: 'https://int.msx.grassavoye.com/assets/extranet/int/exm.html',
  ssoRedirectUrlEmbarkEXM: 'https://int.msx.grassavoye.com/assets/extranet/int/exm-embark.html',
  ssoRedirectUrlEIM: 'https://int.msx.grassavoye.com/assets/extranet/int/eim.html',
  ssoRedirectUrlEmbarkEIM: 'https://int.msx.grassavoye.com/assets/extranet/int/eim-embark.html',
  ssoRedirectUrlEAS: 'https://eas-portal-int.cloudfr.willistowerswatson.com/connect/messagerie',
  ssoClientId: 'msx-spa-int',
  videoUrlEXA: 'http://files.witiwi.fr/Marketing/Videos/Presentation_Messagerie_Espace_Client.mp4',
  faqUrlEXA: 'https://extranet-exa-int.witiwi.fr/fr/infos-pratiques/comment-faire',
  faqUrlERH: 'https://extranet-rh-int.witiwi.fr/fr/faq',
  showV3: false,
  ssoEmbarkClientId: '418f5832-9e24-4ac0-aef5-90d706b4de8e',
  ssoEmbarkClientCountryId: 688,
  ssoEmbarkUrlIssuer: 'https://auth-2-uat.ehr.com/wtwb2cUatEu.onmicrosoft.com/B2C_1A_PEX_Signin/v2.0',
  ssoEmbarkUrl: 'https://auth-dev.ehr.com/wtwb2cDev.onmicrosoft.com/B2C_1A_PEX_SignIn/oauth2/v2.0',
  ssoEmbarkRedirectUrl: 'https://int.msx.grassavoye.com/create',
  ssoEmbarkLogoutRedirectUrl: 'https://int.msx.grassavoye.com/logout'
};
