import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from 'src/environments/environment';


export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    //issuer: 'https://idsvr4.azurewebsites.net',
    issuer: environment.ssoUrlIssuer,

    loginUrl: environment.ssoUrl + '/authorize',
    tokenEndpoint: environment.ssoUrl + '/access_token',
    revocationEndpoint: environment.ssoUrl + '/token/revoke',
    //userinfoEndpoint: 'https://demo.identityserver.io/connect/userinfo',

    // URL of the SPA to redirect the user to after login
    redirectUri: environment.ssoRedirectUrl, // !!! will be overridden in AppComponent

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    //clientId: 'interactive.public.short',
    clientId: environment.ssoClientId,

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    //dummyClientSecret: 'jwtClientSecret',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'write',

    useSilentRefresh: false,
    skipIssuerCheck: true,
    customQueryParams: {'decision': 'allow'},
    //disablePKCE: true,
    waitForTokenInMsec: 3 * 1000,
    sessionChecksEnabled: true,
    clearHashAfterLogin: true,
    showDebugInformation: true,
    nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
  };

