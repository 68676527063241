<div class="container-msx-fluid height-max">
    <div class="row-msx height-max">
        <div >
            <div class="welcome-img img-url" style="background-image:url('{{siteUrl}}/assets/{{welcomeImg}}');">
                <div class="background-text">
                    <div class="welcome" [class.no-faq]="faqLink === undefined">
                        <h1 class="h1-msx col-6 welcome-text font-italic" >{{welcomeTextTop}}</h1>
                        <h1 class="h1-msx col-6 welcome-text font-bold text-bottom" >{{welcomeTextBottom}}</h1>
                        <h1 *ngIf="videoLink !== undefined" class="h1-msx col-6 welcome-text font-italic link-video" ><a href="{{videoLink}}" target="_blank">{{ 'mainPage.linkText' | translate }}</a></h1>
                        <div *ngIf="faqLink !== undefined" class="faq">
                            <button class="btnCustom btn-faq" (click)="openFaq()">
                                <div class="mx-auto">
                                    {{ 'mainPage.question' | translate }}
                                    <div class="font-faq" >{{ 'mainPage.faq' | translate }}</div>
                                </div>
                            </button>
                            <h1 class="h1-msx col-6 welcome-text bottom-text">{{ 'mainPage.faqText' | translate }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>