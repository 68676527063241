import { FilterService } from './filters/filter.service';
import { BreakpointService } from './../../core/services/breakpoint.service';
import { UtilService } from './../../services/util.service';
import { switchMap, tap } from 'rxjs/operators';
import { RequestService } from './../../services/request.service';
import { ConfigService } from './../../services/config.service';
import { GroupService } from './../../services/group.service';
import { CreationService } from 'src/app/pages/creation/creation.service';
import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Request } from 'src/app/models/request';
import { ReplyService } from 'src/app/services/reply.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { RequestPFN } from 'src/app/models/requestPfn';
import { RequestType } from 'src/app/enums/request-type.enum';


@Component({
  selector: 'app-historical-request',
  templateUrl: './historical-request.component.html',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['../../../styles/bootstrap.min.css', './historical-request.component.scss']
})
export class HistoricalRequestComponent implements OnInit {
  showFilters = false;
  showFiltersPfn = false;
  siteUrl = environment.siteUrl;
  rightSelect = false;
  leftSelect = false;
  activeTab: string = RequestType.STANDARD;
  readonly RequestType = RequestType;
  // from rout query
  // @todo is it needed???
  requestType: string = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private configService: ConfigService,
              private groupService: GroupService,
              public utilService: UtilService,
              private filterService: FilterService,
              public requestService: RequestService,
              public breakpointService: BreakpointService,
              private creationService: CreationService,
              private oauthService: OAuthService,
              public replyService: ReplyService,
              private localizationService: LocalizationService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['extranet']) {
        this.configService.extranet = params['extranet'];
      }
      if (params.id !== null && params.id !== undefined) {
        this.replyService.advisorPage = true;
        this.replyService.currentId = params.id;
        this.configService.lang = 'fr-FR';
      }
      if (params.requestType !== null && params.requestType !== undefined) {
        this.requestType = params.requestType;
        // id form request-detail path
        if (this.route.firstChild.snapshot.paramMap.get('id') !== null && this.route.firstChild.snapshot.paramMap.get('id') !== undefined) {
          this.requestService.selectedId = Number(this.route.firstChild.snapshot.paramMap.get('id'));
        }
      }
      if (this.replyService.advisorPage) {
        this.getRequestsAdvisor();
      } else {
        this.getInit();
      }
    });
  }

  getGroups(): void {
    // set groups from creationService without groups with creation to false
    this.groupService.getGroups()
          .subscribe(g => this.creationService.groups = g.groups.filter(gr => gr.creation));
  }

  // @todo Use RequestType enum
  showDetail(request: Request | RequestPFN, type: string = '') {
    this.requestService.selectedId = request.id;
    const isUnread = request.unread;
    request.unread = false;
    if (type === 'standard' && this.requestService.unreadRequests && isUnread) {
      this.requestService.unreadRequests--;
    } else if (type === 'pfn' && this.requestService.unreadRequestsPfn && isUnread) {
      this.requestService.unreadRequestsPfn--;
    } 
    
    console.log('advisorId ' + this.replyService.currentId)
    if (this.replyService.advisorPage){
        this.router.navigate(['/advisor', request.id], { skipLocationChange: true });
    } else if (this.breakpointService.isDesktop) {
        this.router.navigate(['/historical/requests-detail', request.id], { 
          skipLocationChange: true, 
          queryParams: {requestType: type} 
        });
    } else {
        this.router.navigate(['/requests-detail', request.id], { skipLocationChange: true });
    }
  }

  getRequests() {
    this.requestService.getRequestsByDomain().subscribe(requests => this.requestService.requests = requests.requests.map(request => {
        let groupName = this.configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN;
        let typeName = this.configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN;
        request.name = typeName ? typeName : groupName;
        // request.visible = true;
        if(request.id === this.requestService.selectedId) {
            request.unread = false;
        }
        return request}));
  }
  getRequestsAdvisor() {
    this.requestService.getClientIdWithRequestId(this.replyService.currentId).pipe(
      tap(resp => this.replyService.client = resp),
      switchMap(() => (this.requestService.getRequestsAdvisor(this.replyService.client, this.replyService.currentId)).pipe(
        tap(requests => this.requestService.requests = requests.requests.map(request => {

           if (request.id == this.replyService.currentId)
               this.showDetail(request);
           let groupName = request.groupNameFR;
           let typeName = request.typeNameFR;
           request.name = typeName ? typeName : groupName;
          // request.visible = true;
           return request}))
        ),
        )
      ).subscribe();
    }

  getInit() {
    this.requestService.unreadRequests = 0;
    this.requestService.unreadRequestsPfn = 0;
    // requestService.requests
    this.requestService.getRequestsByDomain().pipe(
      tap(requests => this.requestService.requests = requests.requests.map(request => {
        let groupName = this.configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN;
        let typeName = this.configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN;
        request.name = typeName ? typeName : groupName;
        // request.visible = true;
        this.requestReadStatus(request, 'standard');
        return request}))
        // tap(requests => this.requestService.requests = [])
        ).subscribe(() => {
          this.subscribeFilters();
          this.getGroups();
        });
        
    // requestService.requestsPFN
    this.requestService.getRequestsPFN().pipe(
      tap(requests => this.requestService.requestsPFN = requests.requests.map(request => {
        let groupName = this.configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN;
        let typeName = this.configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN;
        request.name = typeName ? typeName : groupName;
        // request.visible = true;
        this.requestReadStatus(request, 'pfn');

        let yourWhat = (request.channel === "P" || request.channel === "R") ? this.localizationService.translate('historical.yourMailFrom') : request.channel === "F" ? this.localizationService.translate('historical.yourFaxFrom') : request.channel === "M" ? this.localizationService.translate('historical.yourEmailFrom') : '';
        // initially it was date format for "P" and "R", and date-time for the rest
        let fromDate = this.format(request.sentDate);
        request.PfnTitle = `${yourWhat} ${fromDate}`;
        return request}))
        // tap(requests => this.requestService.requestsPFN = [])
    ).subscribe(() => {
    this.subscribeFiltersPfn();
    this.getGroups();
    });
  }

  // @todo Use RequestType enum
  requestReadStatus(request: Request | RequestPFN, type: string) {
    if (request.unread && type === 'standard') {
      this.requestService.unreadRequests++;
    } else if (request.unread && type === 'pfn') {
      this.requestService.unreadRequestsPfn++;
    }

    // case when reloading component after clicking on request item
    // we need this because component reloading will lose some of showDetail() settings
    if (request.id === this.requestService.selectedId) {
      if (type === 'standard') {
        this.activeTab = RequestType.STANDARD;
      } else if (type === 'pfn') {
        this.activeTab = RequestType.PFN;
      }
      if (request.unread) {
        request.unread = false;
        if (type === 'standard') {
          this.requestService.unreadRequests--;
        } else if (type === 'pfn') {
          this.requestService.unreadRequestsPfn--;
        }
      }
    }
  }

  requestsCountByChannel (channel: string): number {
    if (channel === RequestType.STANDARD) {
      return this.requestService.requests.length;
    }
    if (channel === RequestType.PFN) {
      return this.requestService.requestsPFN.length;
    }
    if (channel === RequestType.COMMUNITY) {
      //@todo
      return 0;
    }
    return 0;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.filterService.applyFilters(this.requestService.requests);
      this.filterService.applyFiltersPfn(this.requestService.requestsPFN);
    }
  }

  subscribeFilters() {
    this.filterService.cleanFilters();
    this.filterService.filteredRequests$.subscribe((filteredRequests) => {
      this.requestService.requests = filteredRequests
    });
  }

  subscribeFiltersPfn() {
    this.filterService.cleanFilters();
    this.filterService.filteredRequestsPFN$.subscribe((filteredRequestsPFN) => {
      this.requestService.requestsPFN = filteredRequestsPFN
    });
  }

  // when some filter(s) applied 
  hasNoResult(requestType: string): boolean {
    if (requestType === RequestType.STANDARD) {
      return !this.requestService.hasVisibleRequests() && this.filterService.hasAnyFilter();
    } else if (requestType === RequestType.PFN) {
      return !this.requestService.hasVisibleRequestsPFN() && this.filterService.hasAnyFilter();
    }
    return false;
  }

  format(date: Date) {
    return this.utilService.format(date, this.configService.lang);
  }
  formatDateTime(date: Date) {
    return this.utilService.formatDateTime(date, this.configService.lang);
  }
  statusLabel(status: string) {
    return this.utilService.getStatusLabel(status);
  }
  groupImage(groupId: number) {
    return this.utilService.getGroupImageUrl(groupId);
  }
  isEmpty(text: string): boolean {
    return (text === null) || text.trim() === '';
  }

  chanelImage(channel: string): string {
    return (channel === "P" || channel === "R") ? this.siteUrl + '/assets/icon_envelope.svg' : channel === "F" ? this.siteUrl + '/assets/icon_fax.svg' : channel === "M" ? this.siteUrl + '/assets/picto_flux_email.svg' : '';
  }

  advisorVisibleImage(requestUnread: boolean): string {
    return (requestUnread) ? this.siteUrl + '/assets/icon_eye_crossed_black.svg' : this.siteUrl + '/assets/icon_eye_crossed_gray.svg';
  }

  filterIcon(): string {
    if (this.filterService.hasAnyFilter()) {
      return this.siteUrl + '/assets/filter/icon_filters-select.svg';
    }
    return this.siteUrl + '/assets/filter/icon_filters.svg';
  }

  returnZero() {
    return 0;
  }

  dropdownClickHandler(typeValue: string): void {
    this.activeTab = typeValue;
  }

  requestTypename(typeValue: string): string {
    return this.localizationService.translate(`historical.${typeValue}`);
  }

  unreadCount(typeKey: string): number {
    if (typeKey === 'STANDARD') {
      return this.requestService.unreadRequests;
    }
    if (typeKey === 'PFN') {
      return this.requestService.unreadRequestsPfn;
    }
    // @todo add my community case -> if (typeKey === 'COMMUNITY') {....
    // 'return 0' for the default case
    return 5;
  }
  
  getKeyName(value: string) {
    return Object.entries(RequestType).find(([key, val]) => val === value)?.[0];
  }

  headerColsClasses (col: string): string[] {
    const classes: string[] = [];
    const isSpecialSpot: boolean = this.breakpointService.appComponentWidth >= 1000 && this.breakpointService.appComponentWidth < 1070;
    classes.push (
      (isSpecialSpot) ? 'col-12 justify-content-center' : 'col-sm-6',
    );
    if (this.breakpointService.isMobileSmall) {
      classes.push('justify-content-center');
    }
    if (!isSpecialSpot && !this.breakpointService.isMobileSmall && col === 'button') {
      classes.push('justify-content-end');
    }
    return classes;
  }

}
