import { Injectable } from '@angular/core';
import { Group } from 'src/app/models/group';
import { Type } from 'src/app/models/type';


@Injectable({
  providedIn: 'root',
})
export class CreationService {

  selectedGroup: Group;
  selectedType: Type;
  creationLabel: string;
  uploadFiles = new Array();
  hashFiles = new Array();
  requestMessage: string = '';
  formValid: boolean = false;
  groups: Group[] = [];
  step: number = 1;
  stepMax: number = 1;
  over: boolean = false;
  month: string = '';
  year: string = '';
  budget: string = '';
  contractNumber: string = '';
  motive: string = '';
  date: string = '';


  constructor() { }


  clean() {
    this.selectedGroup = null;
    this.selectedType = null;
    this.creationLabel = '';
    this.uploadFiles = new Array();
    this.hashFiles = new Array();
    this.requestMessage = '';
    this.formValid = false;
    this.step = 1;
    this.stepMax = 1;
    this.month = '';
    this.year = '';
    this.budget = '';
    this.contractNumber = '';
    this.motive = '';
    this.date = '';
  }
}
