import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    let day: string | number = '';
    let month: string | number = '';
    if(date) {
      day =  date.day;
      if(date.day.toString().length == 1) {
        day = '0' + date.day.toString();
      }
      month =  date.month;
      if(date.month.toString().length == 1) {
        month = '0' + date.month.toString();
      }
    }
    return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
  }
}