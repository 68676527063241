import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {ConfigService} from '../../services/config.service';

@Injectable()
export class BreakpointService {

    public isDesktop = true;
    public isMobileSmall = false;
    public isMiddleSize = false;
    public appComponentWidth: number;

    constructor(
        public breakpointObserver: BreakpointObserver,
        private configService: ConfigService,
    ) {
      // NOTE: Use the Breakpoint Observer to switch observe
      //       mobile/desktop changes...
      this.breakpointObserver
        .observe(['(min-width: 1000px)', '(max-width: 575px)', '(max-width: 1200px)'])
        .subscribe((state: BreakpointState) => {
          this.setResponsiveProps(state);
        });
    }

    setResponsiveProps (state: BreakpointState | null = null) {
      console.log('setResponsiveProps - appComponentWidth - ', this.appComponentWidth);
      
      // in context of different extranets, it's better to use root app container width
      if (this.appComponentWidth) {
        
        this.isDesktop = this.appComponentWidth >= 1000;
        this.isMobileSmall = this.appComponentWidth <= 575;
        this.isMiddleSize = this.appComponentWidth <= 1200;
        
      } else if (state !== null) {
        // keep this just in case appComponentWidth is not defined for any reason
        this.isDesktop = state.breakpoints['(min-width: 1000px)'];
        this.isMobileSmall = state.breakpoints['(max-width: 575px)'];
        this.isMiddleSize = state.breakpoints['(max-width: 1200px)'];
      } else if (this.configService.isMobile) {
          // fix for the strange behavior in the mobile app
          this.isDesktop = false;
          this.isMobileSmall = true;
          this.isMiddleSize = true;
      }
    }
  }

