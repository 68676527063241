import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from 'src/environments/environment';


export const authCodeFlowConfigAzure: AuthConfig = {
     issuer: 'https://login.microsoftonline.com/' + environment.azureAdTenantId + '/v2.0',
     loginUrl: 'https://login.microsoftonline.com/' + environment.azureAdTenantId + '/oauth2/v2.0/authorize',
     tokenEndpoint: 'https://login.microsoftonline.com/' + environment.azureAdTenantId + '/oauth2/v2.0/token',
     redirectUri: environment.redirectUriAdvisor,
     clientId: environment.azureClientId,
     responseType: 'code',
     //disablePKCE: true,
     strictDiscoveryDocumentValidation: false,
     scope: environment.scopeAzure,
     useSilentRefresh: false,
     skipIssuerCheck: true,
     customQueryParams: {'decision': 'allow'},
     waitForTokenInMsec: 3 * 1000,
     sessionChecksEnabled: true,
     clearHashAfterLogin: false,
     showDebugInformation: true,
     nonceStateSeparator : 'semicolon'
  };

