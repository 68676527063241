<app-main-layout class="min-height-layout max-width-layout centered-layout">
  <div id="left-sidebar" class="container-msx-fluid">
    <div class="row-msx first"
    [class.mobile-small]="breakpointService.isMobileSmall">
        <div class="d-flex" [ngClass]="headerColsClasses('title')">
          <h1 class="h1-msx">{{ 'historical.title' | translate }}</h1>
      </div>
      <div class="btnEchange d-flex" [ngClass]="headerColsClasses('button')">
        <button
                *ngIf="!replyService.advisorPage"
          type="button" 
          class="btnCustom nav-link d-flex align-items-center justify-content-center"
          [routerLink]="['/creation']"
          skipLocationChange="true"
          routerLinkActive="active">{{ 'historical.newRequest' | translate }}<img src='{{siteUrl}}/assets/icon_plus.svg'>
        </button>
      </div>
    </div>
      <p *ngIf="hasNoResult(RequestType.STANDARD) && activeTab === RequestType.STANDARD" class="no-result">
        {{ 'historical.noResult' | translate }}
      </p>  
      <p *ngIf="hasNoResult(RequestType.PFN) && activeTab === RequestType.PFN" class="no-result">
        {{ 'historical.noResult' | translate }}
      </p>  
      <!-- Dropdown navigation -->
      <div class="row-msx" *ngIf="breakpointService.isMobileSmall">
        <div class="col">
          <div ngbDropdown class="d-block">
            <div type="button" class="d-flex justify-content-between align-items-center" id="dropdownRequests" ngbDropdownToggle>
              <div class="position-relative" class="d-flex align-items-center">
                <span class="anchor-text">{{ requestTypename(activeTab) }}</span>
                <span class="position-relative badge-wrapper" [ngClass]="{'transparent': !unreadCount(getKeyName(activeTab))}">
                  <span class="position-absolute badge rounded-circle">
                    <span class="badge-text">{{ unreadCount(getKeyName(activeTab)) }}</span>
                  </span>
                </span>
              </div>
              <img src='{{siteUrl}}/assets/icon_next.svg'>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownRequests">
              <ng-template ngFor let-type [ngForOf]="RequestType | keyvalue : returnZero">
                <button 
                ngbDropdownItem 
                *ngIf="requestsCountByChannel(type.value) && activeTab !== type.value"
                (click)="dropdownClickHandler(type.value)">
                  <div class="position-relative" class="d-flex align-items-center">
                    <span class="anchor-text">{{ requestTypename(type.value) }}</span>
                    <span class="position-relative badge-wrapper" [ngClass]="{'transparent': !unreadCount(type.key)}">
                      <span class="position-absolute badge rounded-circle">
                        <span class="badge-text">{{ unreadCount(type.key) }}</span>
                      </span>
                    </span>
                  </div>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!-- Tab navigation -->
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs historical" [ngClass]="{'mobile': !breakpointService.isDesktop, 'mobile-small': breakpointService.isMobileSmall, 'middle-size': breakpointService.isMiddleSize}">
        <!-- myRequests -->
        <li [ngbNavItem]="RequestType.STANDARD" *ngIf="requestService.requests.length">
          <a ngbNavLink class="d-flex align-items-center">
            <span class="anchor-text">{{ 'historical.myRequests' | translate }}</span>
            <span class="position-relative badge-wrapper" *ngIf="requestService.unreadRequests">
              <span class="position-absolute badge rounded-circle">
                <span class="badge-text">{{requestService.unreadRequests}}</span>
              </span>
            </span>
          </a>
          <ng-template ngbNavContent>
          <!-- myRequests Filters -->
            <div *ngIf="!replyService.advisorPage" class="row-msx filter-btn">
              <div class="col-10"></div>
              <div class="col-2">
                <button
                type="button"
                class="btn-filter"
                (click)="toggleFilters()">
                <img src='{{filterIcon()}}'>
              </button>        
            </div>
            </div>
            <app-filter *ngIf="showFilters"></app-filter>
            <!-- myRequests List -->
            <ul class="list-requests">
              <ng-template ngFor let-request [ngForOf]="requestService.requests">
                  <li *ngIf="request.visible || replyService.advisorPage" 
                  class="row-msx single-request" 
                  [class.selected]="requestService.selectedId === request.id" 
                  [class.internal-request]="request.clientOrInternal === 'I'" 
                  [class.read]="!request.unread" 
                  [class.justify-content-between]="!breakpointService.isMiddleSize" 
                  [class.middle-size]="breakpointService.isMiddleSize"
                  (click)="showDetail(request, 'standard')">
                  <div class="col-1 mark-unread" [ngClass]="{'transparent': !request.unread}"></div>
                  <div class="col-1">
                    <div *ngIf="groupImage(request.groupId) !== ''" class="group-img">
                      <img *ngIf="request.clientOrInternal === 'I'" class="icon-WTW" src='{{siteUrl}}/assets/messages/icon_WTW.svg' >
                      <img *ngIf="request.clientOrInternal === 'C'" src="{{groupImage(request.groupId)}}"  />
                    </div>
                  </div>
                
                  <div [ngClass]="breakpointService.isMiddleSize ? 'col-10' : 'col-6'">
                    <div class="type-name" ngbTooltip="{{request.name}}" tooltipClass="tooltipFamily">
                      {{request.name}} 
                      <span class="visibility-img"
                      *ngIf="!request.visible && replyService.advisorPage">
                        <img src="{{advisorVisibleImage(request.unread)}}"  />
                      </span>
                    </div>
                    <div class="request-label">
                      {{request.label}}
                    </div>
                    <div class="request-date middle-size" *ngIf="breakpointService.isMiddleSize">
                      {{format(request.creationDateTime)}}
                    </div>
                    <div class="request-status middle-size" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-received': request.status === 'RECEIVED', 'request-status-closed': request.status === 'CLOSED', 'request-status-deleted': request.status === 'DELETED', 'request-status-warning': request.status === 'WARNING' }" *ngIf="breakpointService.isMiddleSize">
                      {{statusLabel(request.status)}}
                    </div>
                  </div>
                  <div class="col-2 request-date" *ngIf="!breakpointService.isMiddleSize">
                    {{format(request.creationDateTime)}}
                  </div>
                  <div class="col status-col" *ngIf="!breakpointService.isMiddleSize">
                    <div class="request-status" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-received': request.status === 'RECEIVED', 'request-status-closed': request.status === 'CLOSED', 'request-status-deleted': request.status === 'DELETED', 'request-status-warning': request.status === 'WARNING' }">
                      {{statusLabel(request.status)}}
                    </div>
                  </div>
              </li>
              </ng-template>
            </ul>
          </ng-template>
        </li>
        <!-- myCommunity -->
        <!-- <li [ngbNavItem]="RequestType.COMMUNITY" *ngIf="true">
          <a ngbNavLink class="d-flex align-items-center">
            <span class="anchor-text">{{ 'historical.myCommunity' | translate }}</span>
            <span class="position-relative badge-wrapper">
              <span class="position-absolute badge rounded-circle">
                <span class="badge-text">5</span>
              </span>
            </span>
          </a>
          <ng-template ngbNavContent>
            <ul class="list-requests"></ul>
          </ng-template>  
        </li>   -->
         <!-- otherChannels (PFN) -->
        <li [ngbNavItem]="RequestType.PFN" *ngIf="requestService.requestsPFN.length">
          <a ngbNavLink class="d-flex align-items-center">
            <span class="anchor-text">{{ 'historical.otherChannels' | translate }}</span>
            <span class="position-relative badge-wrapper" *ngIf="requestService.unreadRequestsPfn">
              <span class="position-absolute badge rounded-circle">
                <span class="badge-text">{{requestService.unreadRequestsPfn}}</span>
              </span>
            </span>
          </a>
          <ng-template ngbNavContent>
            <!-- otherChannels (PFN) Filters -->
            <div *ngIf="!replyService.advisorPage" class="row-msx filter-btn">
              <div class="col-10"></div>
              <div class="col-2">
                <button
                type="button"
                class="btn-filter"
                (click)="toggleFilters()">
                <img src='{{filterIcon()}}'>
              </button>        
            </div>
            </div>
            <app-filter *ngIf="showFilters"></app-filter>
            <!-- otherChannels (PFN) List -->
            <ul class="list-requests">      
              <ng-template ngFor let-request [ngForOf]="requestService.requestsPFN">
                <li *ngIf="request.visible || replyService.advisorPage" 
                class="row-msx single-request" 
                [class.selected]="requestService.selectedId === request.id" 
                [class.internal-request]="request.clientOrInternal === 'I'" 
                [class.read]="!request.unread" 
                [class.justify-content-between]="!breakpointService.isMiddleSize" 
                [class.middle-size]="breakpointService.isMiddleSize"
                (click)="showDetail(request, 'pfn')">
                  <div class="col-1 mark-unread" [ngClass]="{'transparent': !request.unread}"></div>
                  <div class="col-1">
                    <div *ngIf="chanelImage(request.channel) !== ''" class="group-img">
                      <img src="{{chanelImage(request.channel)}}"  />
                    </div>
                  </div>
                
                  <div [ngClass]="breakpointService.isMiddleSize ? 'col-10' : 'col-8'">
                    <div class="type-name" ngbTooltip="{{request.PfnTitle}}" tooltipClass="tooltipFamily">
                      {{ request.PfnTitle }} 
                      <span class="visibility-img"
                      *ngIf="!request.visible && replyService.advisorPage">
                        <img src="{{advisorVisibleImage(request.unread)}}"  />
                      </span>
                    </div>
                    <div class="request-label subject pfn" *ngIf="request.channel === 'M'">
                      {{ 'historical.subject' | translate }}: {{request.subject}}
                    </div>
                    <div class="request-label pfn">
                      {{request.name}}
                    </div>
                    <div class="request-status middle-size" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-received': request.status === 'RECEIVED', 'request-status-closed': request.status === 'CLOSED', 'request-status-deleted': request.status === 'DELETED', 'request-status-warning': request.status === 'WARNING' }" *ngIf="breakpointService.isMiddleSize">
                      {{statusLabel(request.status)}}
                    </div>                  
                  </div>
                  <div class="col status-col" *ngIf="!breakpointService.isMiddleSize">
                    <div class="request-status" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-received': request.status === 'RECEIVED', 'request-status-closed': request.status === 'CLOSED', 'request-status-deleted': request.status === 'DELETED', 'request-status-warning': request.status === 'WARNING' }">
                      {{statusLabel(request.status)}}
                    </div>
                  </div>
              </li>
              </ng-template>
            </ul>
          </ng-template>
        </li>
        
      </ul>
      
      <div [ngbNavOutlet]="nav" 
      class="historical" 
      [ngClass]="{'mobile': !breakpointService.isDesktop, 'mobile-small': breakpointService.isMobileSmall}"
      (domChange)="utilService.onDomChange($event)"></div>
  </div>
</app-main-layout>
