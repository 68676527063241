<div class="row-msx" *ngIf="binding">
    <div class="col">

        <div class="mx-auto" >
            <h2 class="h2-msx group-title justify-content-center">{{ 'attachment.title' | translate }}</h2>
        </div>
        <div class="container">
            <div class="row-msx justify-centent-center">
                <div class="mx-auto col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">

                    <div [class]="advisorPage === true || isReplyPage === true ? 'attachments-reply row-msx' : 'attachments row-msx'" appUpload (onFileDropped)="uploadFile($event)">

                        <h3 *ngIf="!binding.attachmentRule.required && !advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentTextOptional' | translate }}</h3>
                        <h3 *ngIf="binding.attachmentRule.required && !advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentTextRequired' | translate }}</h3>
                    
                        <h3 *ngIf="!binding.attachmentRule.required && advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentAdvisorTextOptional' | translate }}</h3>
                        <h3 *ngIf="binding.attachmentRule.required && advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentAdvisorTextRequired' | translate }}</h3>

                        <input name="image[]"
                                id="image"
                                class="uploadInput"
                                type="file"
                                #fileUpload
                                multiple
                                (change)="uploadFile($event.target.files)">

                        <div class="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5" *ngIf="creationService.uploadFiles.length < binding.attachmentRule.maxNb">
                            <button class="btnCustom my-3" [attr.disabled] = "creationService.over? 'disabled' : null"(click)="fileUpload.click()">{{ 'attachment.add' | translate }}<img class="icon-plus" src='{{siteUrl}}/assets/icon_plus.svg'></button>
                        </div>

                        <div  [attr.style] = "creationService.over ? 'opacity:0.3;' : null" [class.maxSize]="isFilesSizeTooLarge(creationService.uploadFiles)" [class.mx-auto]="creationService.uploadFiles.length >= binding.attachmentRule.maxNb" class="col-6 col-xl-4 col-lg-3 col-md-3 col-sm-6 col-xs-6 files-size" >
                            <div class="col-sm-12 col-xl-5 text-size">{{ 'attachment.totalSize' | translate }} :</div>
                                <div class="col-sm-12 col-xl-7 text-size" *ngIf="binding.typeId === 3" > {{formatBytes(filesSize,2)}}/ 2 Mo</div>
                                <div class="col-sm-12 col-xl-7 text-size" *ngIf="binding.typeId !== 3" > {{formatBytes(filesSize,2)}}/ 15 Mo</div>
                        </div>

                        <div class="row-msx mt-3" style="width: 100%;">
                            <div *ngFor="let file of creationService.uploadFiles" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div class="file">
                                    <img class="icon-close" src='{{siteUrl}}/assets/icon_remove.svg' (click)="deleteFile(file)"/>
                                    <img class="icon-file" src='{{siteUrl}}/assets/icon_file.svg'>
                                    <div class="file-name" >{{(file.name.length>25)? (file.name | slice:0:25)+'...':(file.name) }}</div>
                                    <div class="file-size">{{formatBytes(file.size)}}</div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="!isContractCancel">
                            <div *ngIf="!binding.attachmentRule.messageRequired && !isBankUpdate" class="message-title pl">
                                {{ 'attachment.addMessage' | translate }}
                                <span class="message-optional">({{ 'attachment.optional' | translate }})</span>
                            </div>
                            <div *ngIf="binding.attachmentRule.messageRequired && !advisorPage && !isEAC" class="message-title pl">
                                {{ 'attachment.addMessageRequired' | translate }} 
                                <span class="starMandatoryField">*</span>
                            </div>
                            <div *ngIf="binding.attachmentRule.messageRequired && advisorPage" class="message-title pl">
                                {{ 'attachment.advisorAddMessageRequired' | translate }} 
                                <span class="starMandatoryField">*</span>
                            </div>
    
                            <div class="col-md-12 my-2" *ngIf="!isBankUpdate && !isEAC">
                                <textarea
                                    [attr.style] = "creationService.over ? 'opacity:0.3;' : null"
                                    (keyup)="onKeyUp($event)"  
                                    id="requestMessage" 
                                    name="requestMessage" 
                                    [(ngModel)]="creationService.requestMessage" 
                                    [class]="advisorPage === true ? 'textarea form-control-advisor' : 'textarea form-control'"
                                    [maxlength]="advisorPage === true ? 2000 : 1000" 
                                    minlength="0" 
                                    placeholder="{{creationService.requestMessage}}"
                                    spellcheck="true">
                                </textarea>
                                <div class="char-counter">{{messageLength}}/{{advisorPage === true ? 2000 : 1000}}</div>
                            </div>
                            <div *ngIf="isEAC" class="message-title pl">{{ 'attachment.messageTextEAC' | translate }}</div>
                            <div class="col-md-4 my-2" *ngIf="isEAC">
                                <div class="message-title">{{ 'attachment.month' | translate }} <span class="starMandatoryField">*</span></div>
                                <select name="month" id="month" class="form-control" [(ngModel)]="creationService.month" (change)="validForm()">
                                    <option *ngFor="let item of [].constructor(12); let i = index" value="{{'attachment.months.' + (i + 1) | translate}}">{{'attachment.months.' + (i + 1) | translate}}</option>
                                </select>
                            </div>                        
                            <div class="col-md-4 my-2" *ngIf="isEAC">
                                <div class="message-title">{{ 'attachment.year' | translate }} (XXXX) <span class="starMandatoryField">*</span></div>
                                <input name="year" id="year" appDigitOnly maxlength="4" class="form-control" [(ngModel)]="creationService.year" (change)="validForm()">
                            </div>
                            <div class="col-md-4 my-2" *ngIf="isEAC">
                                <div class="message-title">{{ 'attachment.budget' | translate }}</div>
                                <input name="budget" id="budget" class="form-control" [(ngModel)]="creationService.budget" (change)="validForm()">
                            </div>
    
                            <div *ngIf="advisorPage === true && showV3" class="row-msx flexBox-checkbox-button">
                                <div class="flex-checkbox-div">
                                    <p class="pCheckbox message-title pl">{{ 'attachment.attachmentAdvisorTextCheckbox' | translate }} <span class="starMandatoryField">*</span></p>
                                    <ul class="list-checkbox">
                                        <li class="row-msx li-checkbox" *ngFor="let status of checkboxAdvisorStatusList" (click)="selectStatus(status)">
                                                <label class="labelCheckbox">
                                                    <img class="checkedIconCheckbox" src="{{ checkedIcon(status.selected) }}"/><p class="pCheckboxCode">{{ status.code }}</p>
                                                </label>
                                        </li>
                                    </ul>
                                    <div>
                                        <button [disabled]="!creationService.formValid || submited" class="btnCustom btnCheckBox" (click)="isReplyPage ? sendMessage() : !advisorPage ? sendRequest() : sendMessageAdvisor()">{{ 'attachment.send' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-md-12 my-2" *ngIf="isContractCancel" (domChange)="utilService.onDomChange($event)">
                            <div class="message-title">{{ 'attachment.contractNumber' | translate }} <span class="starMandatoryField">*</span> <img class="icon-info" src='{{siteUrl}}/assets/icon_info.svg' data-toggle="tooltip" title="{{ 'attachment.contractNumberInfo' | translate }}"></div>
                            <input name="contractNumber" id="contractNumber" class="form-control" [(ngModel)]="creationService.contractNumber" (change)="validForm()">
                        </div>
                 
                        <div class="col-md-12 my-2" *ngIf="isContractCancel">
                            <div class="message-title">{{ 'attachment.motive' | translate }} <span class="starMandatoryField">*</span></div>
                            <textarea
                            id="motive" 
                            name="motive" 
                            [(ngModel)]="creationService.motive" 
                            (change)="validForm()"
                            class="textarea form-control"
                            minlength="0" 
                            rows="2"
                            spellcheck="true">
                        </textarea>
                        </div>

                        <div class="col-md-4 my-2" *ngIf="isContractCancel">
                            <div class="message-title">{{ 'attachment.date' | translate }} <span class="starMandatoryField">*</span></div>
                            <div class="input-group calendar" (domChange)="utilService.onDomChange($event)">
                                <input class="form-control calendar" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="creationService.date" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="validForm()">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar d-flex justify-content-center align-items-center" (click)="d.toggle()" type="button"><img src='{{siteUrl}}/assets/icon_calendar.svg'></button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="advisorPage !== true || !showV3" class="row-msx justify-content-center sendButton">
                            <button [disabled]="!creationService.formValid || submited" class="btnCustom" (click)="isReplyPage ? sendMessage() : !advisorPage ? sendRequest() : sendMessageAdvisor()">{{ 'attachment.send' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="configService.isMobile === true" class="padding-mobile"></div>
    </div>
</div>


<ngx-spinner  bdColor="rgba(51, 51, 51, 0.8)" template="<img src='{{siteUrl}}/assets/loader.gif' class='img-spinner'/>"></ngx-spinner>
