import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConfigService } from '../../../app/services/config.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: OAuthService, public router: Router, public configService: ConfigService) { }

  canActivate(): boolean {
    if (!environment.ssoEnabled) {
        return true;
    }
    if (location.origin.includes(environment.advisorUrl) ) {
      return false;
    }
    if (!this.auth.hasValidAccessToken()) {
      console.log("AuthGuardService tryLogin");
      this.auth.tryLogin()
      .catch(err => {console.error('tryLogin error', err);})
      .then(()=> {
        this.auth.initCodeFlow();
              }).then(() => {
                console.log('access token', this.auth.getAccessToken());
                console.log('hasValidAccessToken', this.auth.hasValidAccessToken());
                let claims = this.auth.getIdentityClaims();
                 if (claims)
                   {
                    console.log(claims);
                  }
              })
      return false;
    }
    return true;
  }
}