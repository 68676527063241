import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from "rxjs";
import { HttpError } from "./httpError";
import { tap } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { ConfigService } from '../services/config.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { Router } from '@angular/router';
import { CreationService } from 'src/app/pages/creation/creation.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private creationService : CreationService,
                private router: Router,
                private modalService: ModalService,
                private configService: ConfigService,
                private localizationService: LocalizationService,
                private spinnerService: NgxSpinnerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(event => {
            }, err => {
                this.spinnerService.hide();
                let errorMsgs = [];
                if (err instanceof HttpErrorResponse) {
                    if((err.error === 'Microsoft-Azure-Application-Gateway/v2') && req.url.includes('/api/v1/requests') && req.method === 'POST' && err.status === 403){
                        errorMsgs.push(this.localizationService.translate('errorInterceptor.wafMessage'));
                        this.modalService.show({title: this.localizationService.translate('errorInterceptor.modalTitle'), texts: errorMsgs });
                    } else {
                        setTimeout(() => {
                            errorMsgs.push(this.localizationService.translate('errorInterceptor.genericMessage'));
                            this.modalService.show({title: this.localizationService.translate('errorInterceptor.modalTitle'), texts: errorMsgs });
                            if(req.url.includes('/bff/')){
                                this.creationService.clean();
                                this.router.navigate(['/historical'], { skipLocationChange: true });
                            }
                        }, this.configService.ssoRedirectFlag ? 5000 : 1);
                    }
                }
            }));
        }
}