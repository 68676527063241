import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { BreakpointService } from '../services/breakpoint.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  private isOutletActive = false;

  constructor(
    public breakpointService: BreakpointService,
    private elementRef: ElementRef
    ) { }

  ngOnInit(): void {
    this.setResponsiveProps();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setResponsiveProps();
  }
  
  /**
   * Force setting breakpointService props (public isDesktop ,isMobileSmall, isMiddleSize), because breakpointService.breakpointObserver() triggers only on specified breakpoints.
   */
  setResponsiveProps () {
    this.breakpointService.appComponentWidth = this.elementRef.nativeElement.offsetWidth;
    this.breakpointService.setResponsiveProps();
  }

  onOutletActivated($event) {
      // Primarily here for debugging...
      this.isOutletActive = true;
    }

    onOutletDeactivated($event) {
      // Primarily here for debugging...
      this.isOutletActive = false;
    }

}
