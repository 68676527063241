<div class="container-fluid container-creation">
    <div class="row-msx">
        <div class="col">
            <div class="btnClose">
                <a (click)="cancel()"><img src='{{siteUrl}}/assets/icon_remove.svg' ></a>
            </div>
            <div class="attachments-advisor">
                <app-attachments [advisorPage]="advisorPage" [requestId]="sharedId" ></app-attachments>
            </div>
        </div>
    </div>
</div>