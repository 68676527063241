import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { GroupComponent } from './group/group.component';
import { TypeComponent } from './type/type.component';
import { CreationComponent } from './creation.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { UploadDirective } from '../../directives/upload-directive/upload.directive';
import { DigitOnlyDirectiveDirective } from 'src/app/directives/digit-only-directive/digit-only-directive.directive';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DomChangeDirectiveModule } from 'src/app/directives/dom-change-directive/dom-change-directive.module';
/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  if(location.origin === environment.advisorUrl)
        return new TranslateHttpLoader(http, environment.advisorUrl + '/assets/locales/', '.json');
    else
        return new TranslateHttpLoader(http, environment.siteUrl + '/assets/locales/', '.json');
}

@NgModule({
  declarations: [
    CreationComponent,
    GroupComponent,
    TypeComponent,
    AttachmentsComponent,
    UploadDirective,
    DigitOnlyDirectiveDirective    
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    DomChangeDirectiveModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }
            })
  ],
  exports: [CreationComponent, UploadDirective, DigitOnlyDirectiveDirective, AttachmentsComponent]
})
export class CreationModule { }
