<div class="container-fluid container-creation">
    <div class="row-msx">
        <div class="col">
            <div class="btnClose">
                <a [routerLink]="['/']" (click)="cancel()" skipLocationChange><img src='{{siteUrl}}/assets/icon_remove.svg'></a>
            </div>
            <div class="attachments-reply">
                <app-attachments [isReplyPage]="isReplyPage" [requestId]="sharedId" ></app-attachments>
            </div>
        </div>
    </div>
</div>