import { ReplyCreationComponent } from './pages/reply-creation/reply-creation.component';
import { AdvisorCreationComponent } from './pages/advisor-creation/advisor-creation.component';
import { RequestDetailComponent } from './pages/request-detail/request-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { HistoricalRequestComponent } from './pages/historical-request/historical-request.component';
import { CreationComponent } from './pages/creation/creation.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { AttachmentsComponent } from './pages/creation/attachments/attachments.component';
import { AuthGuardService as AuthGuard } from './core/auth/auth-guard.service';
import { AuthGuardAzureService as AuthGuardAzure } from './core/auth/auth-guard-azure.service';

const routes = [
     { path: 'advisor', component: HistoricalRequestComponent, canActivate: [AuthGuardAzure], children: [
                                 { path: ':id', component: RequestDetailComponent , canActivate: [AuthGuardAzure]}
                               ]},
    { path: 'creation', component: CreationComponent, canActivate: [AuthGuard]},
    { path: 'advisor-creation', component: AdvisorCreationComponent, canActivate: [AuthGuardAzure]},
    { path: 'reply-creation', component: ReplyCreationComponent, canActivate: [AuthGuardAzure]},
    { path: 'attachments', component: AttachmentsComponent, canActivate: [AuthGuard] },
    { path: 'requests-detail/:id', component: RequestDetailComponent, canActivate: [AuthGuard] },
    { path: 'historical', component: HistoricalRequestComponent, canActivate: [AuthGuard], children: [
      { path: 'requests-detail/:id', component: RequestDetailComponent , canActivate: [AuthGuard]}
    ]},
    { path: '**', component: HistoricalRequestComponent, canActivate: [AuthGuard], children: [
      { path: '', component: MainPageComponent}
    ]},
  ]

@NgModule({
  imports: [RouterTestingModule.withRoutes(routes)],
  //exports: [RouterTestingModule]
  //imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  providers:[AuthGuard,AuthGuardAzure],
  exports: [RouterModule]
})
export class AppRoutingModule { }
