import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Modal } from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalEmitter = new BehaviorSubject<Modal>(null);
  modal$ = this.modalEmitter.asObservable();
  private modalStateEmitter = new BehaviorSubject<boolean>(false);
  modalState$ = this.modalStateEmitter.asObservable();

  constructor() { }

  show(newModal: Modal): void {
    this.modalEmitter.next(newModal);
  }

  hide(state: boolean = false): void {
    this.modalEmitter.next(null);
    this.setState(state);
  }
  
  setState(state: boolean): void {
    this.modalStateEmitter.next(state);
  }
}
